* {
    margin: 0;
    padding: 0;
    color: white;
}

.container {
    background: #5e678a;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.box {
    background: #454e6e;
    margin-left: 12px;
    margin-right: 12px;
    box-shadow: 10px 10px 10px #3c4560,
    10px 10px 10px #555e88,
    0 0 0 #3c4560 inset,
    0 0 0 #555e88 inset;
}

.box.anim{
    animation: animBox 1s cubic-bezier(0.16, 1, 0.3, 1) forwards;
}

.box.hover:hover {
    background: #52618d;
    transition: all 1s ease;
}

@keyframes animBox {
    0% {
        width: 60px;
        height: 60px;
    }
    100% {
        width: 360px;
        height: 400px;
    }
}

.title{
    margin-left: 8px;
    margin-top: 6px;
    display: flex;
    justify-content: left;
    align-items: center;
    transition: all 0.3s ease;
    overflow: hidden
}

.titleText {
    animation: textAnim 1s forwards;
    white-space: nowrap;
}

.questionContainer {
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 22px;
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 80%;
}

.questionText {
    font-size: 24px;
    font-weight: bolder;
    margin-bottom: 22px;
}

.advanceButton {
    width: 100%;
    padding: 16px 8px;
    background: #165f80;
    margin-top: auto;
    margin-bottom: 6%;
}

.backButton {
    width: 100%;
    height: 32px;
    padding: 8px 8px;
    background: #80163f;
    margin-bottom: 3%;
}

.radioButtons {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    gap: 10px;
    justify-items: center;
    align-items: center;
}

.radioChoice {
    padding: 12px 8px;
    width: 100%;
}

.radioChoicePicked {
    background: #6f7bab;
    padding: 12px 8px;
    width: 100%;
}

.inputBox {
    height: 32px;
    width: 90%;
    padding-left: 24px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid white;
    background: #454e6e;
}

input:focus {
    outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.dollarSign {
    position: relative;
    display: inline-block;
}

.dollarSign::before {
    content: '$';
    position: absolute;
    left: 5px; /* Adjust as needed */
    top: 50%;
    transform: translateY(-55%);
}

button {
    border: none;
    border-radius: 5px;
    background: #4a5b93;
    box-shadow: 10px 10px 10px #3c4560,
    10px 10px 10px #555e88,
    0 0 0 #3c4560 inset,
    0 0 0 #555e88 inset;
}

.resultsContainer {
    font-size: 20px;
    height: 100%;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fadeIn {
    animation: textAnim 1s forwards;
}

@keyframes textAnim {
    0% {
        color: rgba(224, 224, 224, 0);
        opacity: 0;
    }
    33% {
        color: rgba(224, 224, 224, 0.55);
        opacity: 55%;
    }
    66% {
        color: rgba(224, 224, 224, 0.9);
        opacity: 90%;
    }
    100% {
        color: rgba(224, 224, 224, 1);
        opacity: 100%;
    }
}
