html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  color: #e0e0e0;
  font-family: Sans-Serif, serif;
}

.divider {
  border: 1px solid white;
  margin: 8px;
}

ul {
  list-style-type: none;
}